header {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  border-bottom: 2px solid #00a5dc;
}

/* NAVIGATION */
.nav {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  grid-gap: 15px;
  padding: 2px 10px;
  background-color: #fff; /*#e2e4e6;*/
}
.nav a {
  display: flex;
}

.nav__link img {
  max-height: 40px;
}
.nav__link--title {
  margin-left: auto;
  margin-right: auto;
  color: #3c3c3c;
}

.nav__link--title h1 {
  margin: 0;
}

.nav__link--title span {
  color: #009ddb;
}
