.map-display {
  padding: 5px 20px 20px 20px;
  overflow-y: hidden;
  height: calc(100%);
}

.Collapsible{
  padding: 5px 20px 5px 20px;
  border-bottom: 1px solid rgb(177, 177, 177);
  background: #e1dfdf;
}
.Collapsible__contentInner{
  font-weight: normal;
}

/*.Collapsible__contentOuter{
}*/

.Collapsible__trigger{
  cursor: pointer;
  font-weight: bold;
}

.Collapsible__trigger::before{
  font-family: 'Font Awesome 5 Free' ;
  content: '\f054' ;
  margin-right: 5px
}

.Collapsible__trigger.is-open::before{
  content: '\f078' ;
}

/* width */
.map-display::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.map-display::-webkit-scrollbar-track {
  background: rgb(247, 246, 246);
}

/* Handle */
.map-display::-webkit-scrollbar-thumb {
  background: #ababab;
}

/* Handle on hover */
.map-display::-webkit-scrollbar-thumb:hover {
  background: #bababa;
}

.graph-note {
  color: grey;
  font-size: smaller;
  margin-bottom: 5px;
  white-space: pre-wrap;
}
