.modal {
  position: fixed;
  z-index: 500;
  background-color: rgb(243, 243, 243);
  width: 30%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px rgb(49, 49, 49);
  padding: 3em;
  left: 50%;
  top: auto;
  font-size: 14px;
  box-sizing: border-box;
  /*transition: all 0.1s ease-in-out;*/
}

.modal-lever {
  position: fixed;
  z-index: 500;
  background-color: rgb(243, 243, 243);
  width: 50%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px rgb(49, 49, 49);
  padding: 20px;
  left: 30%;
  top: 10%;
  font-size: 14px;
  box-sizing: border-box;
  /*overflow-y: auto;
  max-height: calc(100vh - 210px);*/
  /*transition: all 0.1s ease-in-out;*/
}

/* Modal Header */
.modal-header {
  padding: 2px 16px;
  border-bottom: 2px solid #add6e4;
}

.modal-header h2 {
  margin: 0;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #add6e4;
  color: white;
}

/* Modal Content */
.modal-content {
  /*height: auto !important;*/
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 210px);
  padding: 10px 16px 5px 5px;
  white-space: pre-wrap;
}

.calculate-new-btn {
  width: 25%;
  padding: 10px;
  margin: auto;
  border-radius: 3px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  background-color: #add6e4;
  cursor: pointer;
}

.btn-div {
  display: flex;
  align-items: center;
}

.calculate-new-btn:hover {
  background-color: #607880;
  color: white;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  font-size: 16px;
  margin-bottom: 5px;
}
.mb-4 {
  font-size: 16px;
  margin-bottom: 15px;
}
.sc-desc {
  font-size: 14px;
  margin-bottom: 10px;
}
.lead {
  font-size: 18px;
}
.lead p {
  margin: 10px 15px 20px 15px;
}

.ambition-level {
  font-size: 16px;
}
.ambition-level p {
  margin: 5px 10px 15px 10px;
}

ul {
  padding-left: 20px;
}
