.pathway {
  margin-bottom: 15px;
  width: auto;
  background: #add6e4;
  border: 1px solid #ababab;
  box-shadow: 0 0 10px #c6c5c5;
  padding: 15px;
}

.pathway .lead {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #607880;
}

.pathway-selector {
  width: 75%;
}

.pathway .pathway-control {
  display: flex;
  justify-content: center;
}

.pathway-control .calculate-btn {
  width: 25%;
  margin-left: 0.2rem;
  border-radius: 3px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.pathway-control .info-button {
  margin-left: 20px;
  margin-top: 5px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: white;
  color: #333;
  font-weight: 600;
}
.pathway-control .info-button:hover {
  background-color: #607880;
  color: white;
}
.pathway-control .calculate-btn:hover {
  background-color: #607880;
  color: white;
}
