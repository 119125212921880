.levers {
  padding: 15px 0 10px 15px;
}

.all-levers {
  overflow-y: auto;
  height: calc(100%);
}

.toc-bottom {
  margin: 1px 5px 0 0 ;
  
}


.levers-wrapper {
  height: calc(100% - 90px); /*height: calc(100% - 123px); */
  border: 1px solid #999;
}

/* width */
.all-levers::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.all-levers::-webkit-scrollbar-track {
  background: rgb(247, 246, 246);
}

/* Handle */
.all-levers::-webkit-scrollbar-thumb {
  background: #ababab;
}

/* Handle on hover */
.all-levers::-webkit-scrollbar-thumb:hover {
  background: #bababa;
}
